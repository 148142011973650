import { Dispatch, FunctionComponent, SetStateAction, useRef } from "react";

import { useIntl } from "react-intl";
import {
  InsurelyPrivatePensions,
  ScrapedPensionData,
} from "../../../../../../data/dataLifePensionMove";
import { Move, useTransfer } from "../../TransferContext";
import { AccountType } from "../../../../../../data/dataAccounts";
import { Button, Form, LysaFormRef, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { ActionablePensions } from "../actionablePensions/ActionablePensions";
import { NotMovablePensionsCard } from "../notMovablePensionsCard/NotMovablePensionsCard";

import "./HappyPath.scss";

/**
 * Happy path means we DID find potentially movable pensions from scraping or has added at least one manual pension.
 * To allow for maximal flexibility we present manual added pensions in the list and allow the user
 * to select or deselect the manual added pension alongside the scraped pension. Even though the manual pensions technically already
 * are selected and saved in context. In case user deselect a manual pension we jus overwrite the value saved in context.
 */

interface Props {
  movable: ScrapedPensionData[] | undefined;
  unknown: ScrapedPensionData[] | undefined;
  manual: Move[];
  checked: { [id: string]: boolean };
  setChecked: Dispatch<
    SetStateAction<{
      [id: string]: boolean;
    }>
  >;
  notMovableCount: number;
  next: () => void;
  toNotMovable: () => void;
}

export const HappyPath: FunctionComponent<Props> = ({
  movable,
  unknown,
  manual,
  checked,
  setChecked,
  notMovableCount,
  next,
  toNotMovable,
}) => {
  const formRef = useRef<LysaFormRef>();
  // eslint-disable-next-line
  const [transfer, setTransfer] = useTransfer();
  const intl = useIntl();

  return (
    <article className="transfer-pension-happy-path">
      <Form
        lysaFormRef={formRef}
        onSubmit={(_) => {
          const movableMoves =
            movable
              ?.filter((movableMove) => checked[movableMove.id])
              ?.map((movableMove) => {
                if (InsurelyPrivatePensions.includes(movableMove.pensionType)) {
                  return {
                    type: AccountType.LYSA_PPF,
                    insuranceNumber: movableMove.insuranceNumber,
                    institute: movableMove.insuranceCompany,
                    id: movableMove.id,
                    currentWorth: movableMove.currentWorth,
                    pensionId: movableMove.id,
                  };
                } else {
                  return {
                    type: AccountType.LYSA_TJP,
                    insuranceNumber: movableMove.insuranceNumber,
                    institute: movableMove.insuranceCompany,
                    employerTin: movableMove.insuranceHolderTin,
                    employer: movableMove.insuranceHolderName,
                    id: movableMove.id,
                    currentWorth: movableMove.currentWorth,
                    pensionId: movableMove.id,
                  };
                }
              }) || [];

          const unknownMoves =
            unknown
              ?.filter((unknownMove) => checked[unknownMove.id])
              ?.map((unknownMove) => {
                if (InsurelyPrivatePensions.includes(unknownMove.pensionType)) {
                  return {
                    type: AccountType.LYSA_PPF,
                    insuranceNumber: unknownMove.insuranceNumber,
                    institute: unknownMove.insuranceCompany,
                    id: unknownMove.id,
                    currentWorth: unknownMove.currentWorth,
                    pensionId: unknownMove.id,
                  };
                } else {
                  return {
                    type: AccountType.LYSA_TJP,
                    insuranceNumber: unknownMove.insuranceNumber,
                    institute: unknownMove.insuranceCompany,
                    employerTin: unknownMove.insuranceHolderTin,
                    employer: unknownMove.insuranceHolderName,
                    id: unknownMove.id,
                    currentWorth: unknownMove.currentWorth,
                    pensionId: unknownMove.id,
                  };
                }
              }) || [];

          const manualMoves =
            manual &&
            manual.filter(
              (manualMove) => manualMove.id && checked[manualMove.id]
            );

          const nextMoves = [...movableMoves, ...unknownMoves, ...manualMoves];

          if (formRef.current?.isValid && nextMoves.length > 0) {
            setTransfer({ moves: nextMoves });
            next();
          }
        }}
      >
        <Typography type="h2">
          <TranslatedText id="sweden.transfer-pension.happy-path.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.happy-path.body" />
        </Typography>
        <ActionablePensions
          movable={movable}
          unknown={unknown}
          manual={manual}
          checked={checked}
          setChecked={setChecked}
        />
        <section className="next">
          <NotMovablePensionsCard
            count={notMovableCount}
            onClick={toNotMovable}
          />
          <Button
            block
            variant="primary"
            type="submit"
            label={intl.formatMessage({
              id: "sweden.transfer-pension.happy-path.next-button",
            })}
          />
        </section>
      </Form>
    </article>
  );
};
